import React, { useEffect, useRef } from "react";
import "./MessageFeedbackForm.scss";
import { IoCloseSharp } from "react-icons/io5";
import {
  BOT_MESSAGE_FEEDBACK_TYPE,
  CHAT_MESSAGE_TYPE,
} from "../../../../../../store/consultgptDataSlice/common";
import { IoMdSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChatDisbale,
  resetFeedbackFormData,
  sendConsultResponseFeedbackFormDetails,
  setEditMessageDetails,
  updateFeedbackFormInputText,
  updateFeedbackType,
} from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";

const MessageFeedbackForm = () => {
  const dispatch = useDispatch();
  const feedbackFormRef = useRef(null);
  const { chat_data, userMessageEdit, MessagefeedbackForm } = useSelector(
    (state) => state.consultGptData,
  );

  const timeoutRef = useRef();

  const handleInputChangeHandler = (e) => {
    const inputVal = e.target.value;
    dispatch(updateFeedbackFormInputText(inputVal));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(sendConsultResponseFeedbackFormDetails());
  };

  const getPreviousUserMessageId = () => {
    const negativeMessageIndex = chat_data.currentChatFlow.indexOf(
      userMessageEdit?.negativeResponseMessageId,
    );
    if (negativeMessageIndex > 0) {
      for (let i = negativeMessageIndex - 1; i >= 0; i--) {
        const messageId = chat_data.currentChatFlow[i];
        const message = chat_data.messages[messageId];
        if (message && message.type === CHAT_MESSAGE_TYPE.USER) {
          return {
            message_id: messageId,
            parent_id: message?.parent_message,
            message_text: message?.content,
          };
        }
      }
    }
    return null;
  };

  const handleEditUserPreviousQuery = () => {
    const editMessagedetails = getPreviousUserMessageId();
    dispatch(handleChatDisbale(true));
    dispatch(
      setEditMessageDetails({
        isMessageEditMode: true,
        editMessageId: editMessagedetails?.message_id,
        editMessageParentId: editMessagedetails?.parent_id,
        editMessageText: editMessagedetails?.message_text,
      }),
    );
    dispatch(resetFeedbackFormData());

    // Setting the timeout and to store its ID
    timeoutRef.current = setTimeout(() => {
      const targetMessage = document.getElementById(
        editMessagedetails?.message_id,
      );
      if (targetMessage) {
        targetMessage.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      timeoutRef.current = null; // Clearing ref after execution
    }, 0);
  };

  const handleToggleFeedback = (type) => {
    if (type === BOT_MESSAGE_FEEDBACK_TYPE.QUERY_UPDATE) {
      handleEditUserPreviousQuery();
    } else {
      dispatch(updateFeedbackType(type));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        feedbackFormRef.current &&
        !feedbackFormRef.current.contains(event.target)
      ) {
        dispatch(resetFeedbackFormData());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    // Cleanup
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className="tools_consult_message_feedback_form_wrapper"
      ref={feedbackFormRef}
    >
      <div className="tools_consult_message_feedback_form_header_wrapper">
        <span className="tools_consult_message_feedback_form_heading_text">
          Tell us what went wrong:
        </span>
        <button
          className="tools_consult_message_feedback_form_close_btn"
          onClick={() => dispatch(resetFeedbackFormData())}
        >
          <IoCloseSharp />
        </button>
      </div>
      <div className="tools_consult_message_feedback_form_btn_wrapper">
        {Object.entries(BOT_MESSAGE_FEEDBACK_TYPE).map(([key, label]) => {
          return (
            <button
              key={key}
              className={`tools_consult_message_feedback_form_btn ${
                MessagefeedbackForm.feedbackData.feedbackCategory === label
                  ? "tools_consult_message_feedback_form_active_btn"
                  : ""
              }`}
              onClick={() => handleToggleFeedback(label)}
            >
              {label}
            </button>
          );
        })}
      </div>

      {MessagefeedbackForm.feedbackData.feedbackCategory !== null && (
        <div className="tools_consult_message_feedback_form_input_container">
          <div className="tools_consult_message_feedback_form_header_wrapper">
            <span className="tools_consult_message_feedback_form_heading_text">
              Tell us more about it to help us improve Consult.ai
            </span>
          </div>
          <form
            onSubmit={handleFormSubmit}
            className="tools_consult_message_feedback_form_input_wrapper"
          >
            <input
              type="text"
              className="tools_consult_message_feedback_form_input"
              placeholder="Enter details here"
              autoComplete="off"
              onChange={handleInputChangeHandler}
            />
            <button
              className="tools_consult_message_feedback_form_btn"
              type="submit"
            >
              <IoMdSend />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MessageFeedbackForm;
