import React, { useEffect, useRef, useState } from "react";

import "./ToolDownloadBtn.scss";
import { getAuthData, postAuthData } from "../../../helpers/request";
import { ERROR_REGEX, ToolTipError, getAccessLogData } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import { FILE_ENCRYPTION_STATES } from "../../../client_module/ClientComponents";
import {
  getToolsFileDetails,
  getToolsFileList,
} from "../../../store/toolsDataSlice/toolsDataSlice";
import { useParams } from "react-router-dom";
import {
  setAutoEncryptTimer,
  setEncrypted,
  setToolSecureKey,
} from "../../../store/utilsDataSlice/utilsDataSlice";
import { setAccessLogData } from "../../../store/userSharedata/userShareDataSlice";
import toast from "react-hot-toast";

const ToolDownloadBtn = () => {
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const { asgn_id } = useParams();

  const time_to_encrypt = 3600;
  const { tools_details_data } = useSelector((state) => state.toolsData);

  useEffect(() => {
    dispatch(setEncrypted(tools_details_data?.data?.file_stage));
    let interval = null;

    if (
      tools_details_data?.data?.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD
    ) {
      const timer_date = new Date(tools_details_data?.data?.expire_time);
      const time_left_to_encrypt = Math.floor(
        (timer_date.getTime() - Date.now()) / 1000,
      );

      dispatch(setAutoEncryptTimer(time_left_to_encrypt));
      interval = setInterval(() => {
        setAutoEncryptTimer((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            // if (devEnv) {
            //   setClientDisplayedFile(dummy_assignment.last_assignment); // Setting Dummy Data
            //   setUserId(dummy_assignment.last_assignment);
            //   // setClientDisplayedFile(dummy_last_external_assignments.last_assignment); // Setting External assignments Dummy Data
            //   return;
            // } else {
            //   // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`)
            //   getAuthData(
            //     `${API_URL}/api-client/client/v1/all-assignments/?id=${asgn_id}`,
            //   )
            //     .then((res) => {
            //       if (res.success === true) {
            //         setClientDisplayedFile(res.last_assignment);
            //         // setNotFoundError(false)
            //       }
            //     })
            //     .catch((err) => {
            //       // console.error(err);
            //       setNotFoundError(true);
            //     });
            // }
            dispatch(getToolsFileDetails({ file_id: asgn_id }));

            return time_to_encrypt;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [tools_details_data, asgn_id]);

  const fetch_access_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setAccessLogData(res?.data));
        } else {
          toast.error(res?.message || "Error while fetching Data!!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  //   const secureKeyContext = useContext(SecureKeyContext);
  const fetchId = useRef(null);
  //   const { setClientFilesArray } = useContext(ClientFilesArrayContext);
  //   const { clientDisplayedFile, setClientDisplayedFile } = useContext(
  //     ClientDashboardFileContext,
  //   );
  //   const { setUserId } = useContext(HomeRecentAsgnContext);
  //   const API_URL = useContext(ApiContext);
  //   const { encryptedState } = useContext(EncryptedContext);
  //   const { autoEncryptTimer } = useContext(AutoEncryptTimerContext);
  const [fetchError, setFetchError] = useState(null);
  //   const { setNotFoundError } = useContext(NotFoundErrorContext);
  const [resendBtnMsg, setResendBtnMsg] = useState(null); // eslint-disable-line no-unused-vars
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {

  // }, [tools_details_data]);

  const { auto_encrypt_file_timer, tool_encrypted_state } = useSelector(
    (state) => state.utilsData,
  );
  const updateSidebarFiles = () => {
    if (active_tool_type) {
      dispatch(getToolsFileList({ tool_type: active_tool_type }));
    }
  };

  const resendDataFn = (btn) => {
    const data = {
      assignment_id: tools_details_data?.data?.tools_assignment_id,
    };
    postAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/re-decrypt/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res?.success === true) {
          fetch_access_log();
          // dispatch(getToolsFileDetails({ file_id: asgn_id }));
          btnDisableFn(btn, true, true);
        }
      })
      .catch((err) => {
        btnDisableFn(btn, false, false); // enabling button since there is an error
        console.error(err);
      });
  };

  const btnDisableFn = (btn, disable = true, timer = false) => {
    btn.disabled = disable;
    if (timer) {
      btn.innerText = "Mail Sent!";
      setTimeout(() => {
        let t = 30; // timer time
        let timer_interval = setInterval(() => {
          t--;
          if (t > 0) {
            btn.innerText = `Wait ${t}s`;
            btn.classList.add("tools-decrypt-resend-btn-timer");
          } else {
            btn.innerText = "Resend Email";
            btn.disabled = false;
            btn.classList.remove("tools-decrypt-resend-btn-timer");
            clearInterval(timer_interval);
          }
        }, 1000);
      }, 2000);
    }
  };

  const handleResend = (e) => {
    const resendBtn = e.target.closest("button");
    if (resendBtn.disabled) return;
    btnDisableFn(resendBtn, true); // disabling the button
    resendDataFn(resendBtn);
  };

  const handleKeySubmit = () => {
    const fetchFormData = {
      secure_key: fetchId.current.value,
      assignment_id: tools_details_data?.data?.tools_assignment_id,
      // batch_id : clientDisplayedFile.batch_id
    };
    // setBtn(FILE_ENCRYPTION_STATES.DOWNLOAD)
    if (!fetchId.current.value) {
      // Checking for each input
      // fetchId.current.placeholder = "Secure Key empty!";
      setFetchError("Enter Secure Key");
      setDisableBtn(false);
      // fetchId.current.style.borderColor ="red"
    } else {
      // If input is given then the red border for invalid input is removed
      fetchId.current.style.borderColor = "var(--dark-blue,#112949)";
      postAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/download/`,
        getAccessLogData(fetchFormData),
      )
        .then((res) => {
          // if (res.message === "Client User can download file Successfully") {
          if (res?.success === true) {
            dispatch(getToolsFileDetails({ file_id: asgn_id }));
            dispatch(setToolSecureKey(fetchFormData.secure_key));
            fetch_access_log();
            updateSidebarFiles();
          } else {
            fetchId.current.focus();
            fetchId.current.style.borderColor = "red";
            setFetchError(res?.message);
            setDisableBtn(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setDisableBtn(false);
        });
    }
  };

  const fetchAPI = (api_url, data) => {
    if (!api_url) {
      dispatch(getToolsFileDetails({ file_id: asgn_id }));
      return true;
    }
    postAuthData(api_url, data)
      .then((res) => {
        if (res?.success === true) {
          dispatch(getToolsFileDetails({ file_id: asgn_id }));
          dispatch(getToolsFileList({ tool_type: active_tool_type }));
          fetch_access_log();
        } else {
          toast.error(res?.message || "Error Fetching Data!!!", {
            id: "download-err",
          });
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went Wrong!!!", { id: "download-err" });
        setDisableBtn(false);
      });
    // updateSidebarFiles();
  };

  const onFileStateBtnClick = (e) => {
    e.preventDefault();
    setDisableBtn(true);
    const data = {
      assignment_id: tools_details_data?.data?.tools_assignment_id,
    };
    let api_url;
    switch (tool_encrypted_state) {
      case FILE_ENCRYPTION_STATES.ENCRYPTED:
        // setBtn(FILE_ENCRYPTION_STATES.DECRYPTED);
        api_url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/decrypt/`;
        fetchAPI(api_url, getAccessLogData(data));
        break;
      case FILE_ENCRYPTION_STATES.DECRYPTED:
        handleKeySubmit();
        break;
      case FILE_ENCRYPTION_STATES.DOWNLOAD:
        // setBtn(FILE_ENCRYPTION_STATES.ENCRYPTED);
        api_url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/encrypt/`;
        fetchAPI(api_url, getAccessLogData(data));
        setDisableBtn(false);
        break;
      default:
        api_url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/decrypt/`;
        fetchAPI(api_url, getAccessLogData(data));
        setDisableBtn(false);
        break;
    }
  };

  switch (tool_encrypted_state) {
    case FILE_ENCRYPTION_STATES.ENCRYPTED:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <button
            className="client-btns tool-client-secure-key-btn client-decrypt-btn-tool"
            disabled={disableBtn}
            onClick={onFileStateBtnClick}
          >
            Decrypt
          </button>
        </div>
      );
    case FILE_ENCRYPTION_STATES.DECRYPTED:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <form
            className="tool-client-dashboard-file-decrypt-form"
            onSubmit={onFileStateBtnClick}
          >
            <div className="tool-decrypt-key-input-error-contianer">
              <input
                className="client-dashboard-input decrypt-key-input"
                type="text"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                ref={fetchId}
                placeholder="Enter Secure Key"
              />
            </div>
            <button
              className="client-btns tool-client-secure-key-btn"
              disabled={disableBtn}
              type="submit"
              id="tool-client-decrypt-key-btn"
            >
              Decrypt
            </button>
          </form>
          <div className="client-decrypt-btn-info-panel">
            {fetchError ? (
              <div className="client-decrypt-btn-info decrypt-key-error">
                {fetchError}
              </div>
            ) : (
              <div className="client-decrypt-btn-info client-decrypt-check-mail">
                Check your email for Secure Key
              </div>
            )}
            {resendBtnMsg ? (
              <div className="tool-decrypt-resend-btn">{resendBtnMsg}</div>
            ) : (
              <button
                className="tool-decrypt-resend-btn"
                onClick={handleResend}
              >
                Resend Email
              </button>
            )}
          </div>
        </div>
      );
    case FILE_ENCRYPTION_STATES.DOWNLOAD:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <div className="tool-client-timer-container">
            {/* This below button will show the encrypt now button with the timer */}
            {/* <button className="client-btns client-secure-key-btn" id="client-secure-encrypt-btn" onClick={onFileStateBtnClick}>
                            <span id="client-timer-text">Encrypt Now</span>
                            <span id="client-timer-span" style={{width:(autoEncryptTimer/(3600*((clientDisplayedFile.duration==="hrs") ? 24: 1)))*100 +"%"}}></span>
                        </button> */}
            <span className="client-secure-timer">
              {Math.floor(
                auto_encrypt_file_timer /
                  (tools_details_data?.data?.duration === "hrs" ? 3600 : 60),
              )}{" "}
              {tools_details_data?.data?.duration} remaining
            </span>
            <span
              className="client-encrypt-now-btn"
              onClick={onFileStateBtnClick}
            >
              Encrypt Now
            </span>
          </div>
        </div>
      );

    default:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <button
            className="client-btns tool-client-secure-key-btn"
            onClick={onFileStateBtnClick}
          >
            Decrypt
          </button>
        </div>
      );
  }
};

export default ToolDownloadBtn;
