import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { RxZoomIn } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import "./PdfPreview.scss";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import TOOLS_MODALS from "../../../../../ToolsConstants/TOOLS_MODALS";
import { setModal } from "../../../../../../store/utilsDataSlice/utilsDataSlice";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import {
  updatePdfFileLoading,
  updateSelectedReferencePage,
} from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";

const PdfCustomPageLayer = ({
  renderPageProps,
  popbtn,
  pages,
  selectedPage,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Mark the page as rendered when the canvas layer is rendered
    if (renderPageProps.canvasLayerRendered) {
      renderPageProps.markRendered(renderPageProps.pageIndex);
    }
  }, [renderPageProps.canvasLayerRendered]);

  const handleOpenPdfPreviewModal = (page) => {
    dispatch(setModal(TOOLS_MODALS.CONSULT_PDF_PREVIEW_POP_UP));
    dispatch(updateSelectedReferencePage(page));
  };

  return (
    <>
      {renderPageProps.canvasLayer.children}

      <div className="consult_pdf_page_overlay">
        <div className="consult_pdf_page_number_wrapper">
          <span
            className={`consult_pdf_page_number ${
              selectedPage === renderPageProps.pageIndex + 1
                ? "consult_pdf_page_number_highlighted"
                : ""
            }`}
          >
            {renderPageProps.pageIndex + 1}
          </span>
        </div>
        {pages.includes(renderPageProps.pageIndex + 1) && popbtn && (
          <>
            <div className="pdf_page_pop_up_reference_page_wrapper">
              <button
                className="pdf_page_pop_up_btn"
                onClick={() =>
                  handleOpenPdfPreviewModal(renderPageProps.pageIndex + 1)
                }
              >
                <RxZoomIn className="pdf_page_pop_up_btn_icon" />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const renderPageDocs = (props, popbtn, pages, selectedPage) => (
  <PdfCustomPageLayer
    renderPageProps={props}
    pages={pages}
    popbtn={popbtn}
    selectedPage={selectedPage}
  />
);

const PdfPreview = ({ popbtn = false }) => {
  const { file_preview } = useSelector((state) => state.consultGptData);
  const [currentReferenceIndex, setCurrentReferenceIndex] = useState(0);
  const dispatch = useDispatch();

  // Initializing the page navigation plugin
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const initialPage =
    file_preview?.selected_reference_page !== undefined
      ? file_preview?.selected_reference_page - 1
      : 0;

  const handleJumpToNextReference = () => {
    if (currentReferenceIndex < file_preview?.reference_pages.length - 1) {
      const nextIndex = currentReferenceIndex + 1;
      jumpToPage(file_preview?.reference_pages[nextIndex] - 1);
      setCurrentReferenceIndex(nextIndex);
      dispatch(
        updateSelectedReferencePage(file_preview?.reference_pages[nextIndex]),
      );
    }
  };

  const handleJumpToPreviousReference = () => {
    if (currentReferenceIndex > 0) {
      const prevIndex = currentReferenceIndex - 1;
      jumpToPage(file_preview?.reference_pages[prevIndex] - 1);
      setCurrentReferenceIndex(prevIndex);
      dispatch(
        updateSelectedReferencePage(file_preview?.reference_pages[prevIndex]),
      );
    }
  };

  useEffect(() => {
    // Sync the current reference index with the selected_reference_page
    if (file_preview?.selected_reference_page !== undefined) {
      const index = file_preview.reference_pages?.findIndex(
        (page) => page === file_preview.selected_reference_page,
      );
      setCurrentReferenceIndex(index !== -1 ? index : 0); // Default to 0 if not found
    }
  }, [file_preview?.selected_reference_page, file_preview?.reference_pages]);

  return (
    <div className="rpv-core__viewer tools_consult_pdf_container">
      {!file_preview?.is_document_loaded && popbtn && (
        <div className="file_preview_downloading">Loading preview!</div>
      )}
      <div className="tools_consult_pdf_wrapper_container">
        {file_preview?.file_preview_url && (
          <div className="tools_consult_pdf_wrapper">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer
                fileUrl={file_preview?.file_preview_url}
                initialPage={initialPage}
                plugins={[pageNavigationPluginInstance]}
                onDocumentLoad={() => {
                  // setPreviewFileLoaded(true);
                  dispatch(updatePdfFileLoading(true));
                  if (file_preview?.reference_pages.length > 0) {
                    jumpToPage(
                      file_preview?.reference_pages[currentReferenceIndex] - 1,
                    );
                  }
                }}
                renderPage={(props) =>
                  renderPageDocs(
                    props,
                    popbtn,
                    file_preview?.reference_pages,
                    file_preview?.selected_reference_page,
                  )
                }
              />
            </Worker>
          </div>
        )}
        {file_preview?.reference_pages?.length > 1 &&
          file_preview?.is_document_loaded && (
            <div className="tools_consult_pdf_reference_carousel_container">
              <div className="tools_consult_pdf_reference_carousel_inner_container">
                <div>
                  <span>References</span>
                </div>
                <div className="tools_consult_pdf_reference_carousel_wrapper">
                  <button
                    className="tools_consult_pdf_reference_carousel_btn"
                    onClick={handleJumpToPreviousReference}
                    disabled={currentReferenceIndex === 0}
                  >
                    <FaAngleLeft />
                  </button>
                  <span className="tools_consult_pdf_reference_carousel_counter">
                    {currentReferenceIndex + 1}/
                    {file_preview?.reference_pages?.length}
                  </span>
                  <button
                    className="tools_consult_pdf_reference_carousel_btn"
                    onClick={handleJumpToNextReference}
                    disabled={
                      currentReferenceIndex ===
                      file_preview?.reference_pages.length - 1
                    }
                  >
                    <FaAngleRight />
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default PdfPreview;
