import React from "react";
import "./FileInfoSection.scss";
import { lazy_load_image } from "../../..";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { getToolsFileDetails } from "../../../store/toolsDataSlice/toolsDataSlice";
import TOOLS_TYPE from "../../ToolsConstants/TOOLS_TYPE";
import { ImWarning } from "react-icons/im";
import getToolTypeIcon from "../../../utils/getToolTypeIcon";
import getFileUnitType from "../../../utils/getFileUnitType";
import { MdOutlineOutput } from "react-icons/md";

import {
  setActiveFileCard,
  toggleShowToolsSidebar,
} from "../../../store/utilsDataSlice/utilsDataSlice";
import { FaAngleLeft, FaUserPlus } from "react-icons/fa";
import dateTimeConverter, {
  DATE_FORMATS,
} from "../../../utils/dateTimeConverter";
import ToolDecryptComp from "./ToolDecryptComp";
import TOOL_FILE_CARD from "../../ToolsConstants/TOOL_FILE_CARD";
import TOOLS_PROGRESS from "../../ToolsConstants/TOOLS_PROGRESS";

const FileInfoSection = () => {
  const dispatch = useDispatch();
  // const { asgn_id } = useParams();
  const { tools_details_data, output_status } = useSelector(
    (state) => state.toolsData,
  );
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const { active_file_card } = useSelector((state) => state.utilsData);
  const handleBack = () => {
    dispatch(toggleShowToolsSidebar(true));
  };

  // useEffect(() => {
  //   console.log(output_status.data.status);
  // }, []);
  // useEffect(() => {
  //   // console.log(dispatch); // FIX: REMOVE
  //   // getting file details
  //   dispatch(getToolsFileDetails({ file_id: asgn_id })); // FIX: REMOVE COMMENT
  // }, [asgn_id]);
  const characterLimiter = (value, limit = 50, mobileLimit = 60) => {
    const reducedValue =
      value !== undefined && value !== null && value !== ""
        ? String(value)
        : "-";

    const extensionIndex = reducedValue.lastIndexOf(".");
    const hasExtension =
      extensionIndex !== -1 && extensionIndex < reducedValue.length - 1;
    const extension = hasExtension ? reducedValue.slice(extensionIndex) : "";

    let slicedValue;
    const isMobile = window.innerWidth < 500;
    const maxLimit = isMobile ? mobileLimit : limit;

    if (reducedValue.length <= maxLimit) {
      slicedValue = reducedValue;
    } else {
      slicedValue = reducedValue.slice(0, maxLimit - extension.length) + "...";
      if (hasExtension) {
        slicedValue += extension;
      }
    }

    //adding space for mobile texts as we need space for the name to go to 2nd line as given in requirements
    if (isMobile && slicedValue.length > 30) {
      const first35Chars = slicedValue.slice(0, 30);
      if (!first35Chars.includes(" ")) {
        slicedValue = first35Chars + " " + slicedValue.slice(30);
      }
    }

    return slicedValue;
  };

  return (
    <section className="file_info_section">
      <div className="file_head">
        <div className="file_info_back" onClick={handleBack}>
          <FaAngleLeft size={18} />
        </div>
        <img
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + getToolTypeIcon(active_tool_type)}
          alt={`${active_tool_type}.ai`}
        />
        {/* <div className="file_head_logo">
          <img
            className="lazy-img"
            onLoad={lazy_load_image}
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="EZ"
          />
        </div>
        <div className="file_head_tool_name">
          <span className="file_head_tool_name-txt">{active_tool_type}</span>.ai
        </div> */}
      </div>
      <div
        className="file_info_name"
        title={tools_details_data?.data?.file_name}
      >
        {characterLimiter(tools_details_data?.data?.file_name)}
      </div>
      <div className="file_info_coin_wrapper">
        <div className="file_info_coin">
          <div className="coin_svg"></div>
          <div className="coin_val">
            {tools_details_data.data?.req_ez_coins || "-"}
          </div>
        </div>
        {output_status.data.download_links?.err ? (
          <div
            className="file_info_error"
            title={`ERROR: ${output_status?.data?.download_links?.err}`}
          >
            <ImWarning size={18} />
            <span>Error</span>
          </div>
        ) : (
          <div className="file_info_coin_warning">
            Will be charged upon file download
          </div>
        )}
      </div>
      {output_status?.data?.download_links?.err && (
        <div
          className="file_info_error-new"
          title={`ERROR: ${output_status.data.download_links?.err}`}
        >
          <ImWarning size={18} />
          <span>Error</span>
        </div>
      )}
      <div className="file_info_items">
        <div className="file_info_item">
          <div className="file_info_item_label">
            {`${
              // tools_details_data.data?.type_count ||
              getFileUnitType({
                cur_tool_type: active_tool_type,
                file_name: tools_details_data.data?.file_name,
                plural: false,
              })
            } Count`}
          </div>
          <div className="file_info_item_value">
            {tools_details_data?.data?.count || "-"}
          </div>
        </div>
        {active_tool_type === TOOLS_TYPE.FLIP && (
          <div className="file_info_item">
            <div className="file_info_item_label">Time Saved</div>
            <div className="file_info_item_value">
              {tools_details_data.data?.time_saved || "-"}
            </div>
          </div>
        )}
        <div className="file_info_item file_info_mobile">
          <div className="file_info_item_label">Uploaded</div>
          <div className="file_info_item_value">
            {tools_details_data.data?.created_at
              ? dateTimeConverter(
                  tools_details_data.data.created_at,
                  DATE_FORMATS.DD_MM_YY_TIME,
                )
              : "-"}
          </div>
        </div>
      </div>
      <div className="file_info_iitem">
        <div className="file_info_iitem_label">Uploaded</div>
        <div className="file_info_iitem_value">
          {tools_details_data.data?.created_at
            ? dateTimeConverter(
                tools_details_data.data.created_at,
                DATE_FORMATS.DD_MM_YY_TIME,
              )
            : "-"}
        </div>
      </div>
      {!output_status.data.download_links?.err &&
        (output_status?.data?.status === TOOLS_PROGRESS.SUCCESS ||
          tools_details_data?.data?.status === TOOLS_PROGRESS.SUCCESS) && (
          <ToolDecryptComp tools_details_data={tools_details_data} />
        )}
      <div className="share_output_tabs">
        <div className="tools_share_btn">
          <button
            className={`client-dashboard-selector-btn ${
              active_file_card === TOOL_FILE_CARD.PREVIEW &&
              "tool-client-dashboard-selector-btn-active"
            }`}
            id="tool-client-share-selector-btn"
            onClick={(e) => dispatch(setActiveFileCard(TOOL_FILE_CARD.PREVIEW))}
            title="Check the Output"
          >
            <span>OUTPUT</span>
            <MdOutlineOutput />
          </button>
        </div>
        <div className="tools_share_btn">
          <button
            className={`client-dashboard-selector-btn ${
              active_file_card === TOOL_FILE_CARD.SHARE &&
              "tool-client-dashboard-selector-btn-active"
            }`}
            id="tool-client-share-selector-btn"
            onClick={(e) => dispatch(setActiveFileCard(TOOL_FILE_CARD.SHARE))}
            title="Share this file"
          >
            <span>SHARE</span>
            <FaUserPlus />
          </button>
        </div>
      </div>
    </section>
  );
};

export default FileInfoSection;
