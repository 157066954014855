// Client TopNav CSS
import toast from "react-hot-toast";
import { lazy_load_image } from "../../..";
import { postAuthData } from "../../../helpers/request";
import "./ToolsTopNav.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import company_code_data from "../../../archive/test_data/company_code.json";
import { nameLimiter } from "../../../utils/nameLimiter";
import {
  closeArchiveSidebar,
  closeFilesSidebar,
} from "../../../client_module/Nav/ClientSidenav/ClientSidenav";
import { useState } from "react";
const ToolsTopNav = () => {
  const history = useNavigate();
  const { profile_data, comp_code } = useSelector((state) => state.profileData);
  const [loading, setLoading] = useState(false);
  const toggleLogoutBox = (e) => {
    if (
      e.target.closest(".st-client-user") ||
      e.target.closest(".st-client-logout-box")
    ) {
      const logoutBox = document.querySelector(".st-client-logout-box");
      if (getComputedStyle(logoutBox).display === "none") {
        document.querySelector(".st-client-logout-box").style.display =
          "inline-flex";
        // document.querySelector('.st-client-user-name').style.color = "var(--dark-blue,#112949)"
      } else if (getComputedStyle(logoutBox).display === "flex") {
        document.querySelector(".st-client-logout-box").style.display = "none";
      }
    } else if (
      !e.target.closest(".st-client-user") &&
      !e.target.closest(".st-client-logout-box")
    ) {
      document.querySelector(".st-client-logout-box").style.display = "none";
    }
  };

  const handleLogout = async () => {
    try {
      setLoading(true);

      const res = await postAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/auth/v1/logout/`,
      );

      if (res?.success) {
        toast.success("Logged out successfully!", { id: "logout-success" });

        window.localStorage.clear();
        window.sessionStorage.clear();

        history(
          comp_code && company_code_data.includes(comp_code)
            ? `/${comp_code}`
            : "/",
        );
      } else {
        toast.error(res?.message || "Logout Failed", { id: "logout-fail" });
      }
    } catch (err) {
      console.error("Logout error:", err);
      toast.error("Something went wrong", { id: "logout-fail" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="st-client-topnav">
      <Link
        className="st-client-ez-logo-topnav"
        // to="/decrypt"
        to="/home"
      >
        <img
          className="lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ"
        />
      </Link>
      <div
        className="st-client-user glass-container-hover"
        onClick={toggleLogoutBox}
      >
        <div className="st-client-user-name">
          Hi {nameLimiter(profile_data?.first_name || "User")},
        </div>
        <img
          className="st-client-user-img "
          src={
            profile_data?.image
              ? `${process.env.REACT_APP_API_URL}` + profile_data.image
              : `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`;
          }}
          alt={"first_name"}
        />
      </div>
      <div className="st-client-logout-box">
        <div
          className="st-client-logout-btns"
          id="st-client-user-name-btn"
          onClick={toggleLogoutBox}
        >
          <div
            className="st-client-user-name"
            id="st-client-logout-box-user-name"
          >
            Hi {nameLimiter(profile_data?.first_name || "User")},
          </div>
          <img
            className="st-client-user-img"
            src={
              profile_data?.image
                ? `${process.env.REACT_APP_API_URL}` + profile_data.image
                : `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`;
            }}
            alt={"first_name"}
          />
        </div>
        <button
          className="st-client-logout-btns"
          onClick={(e) => {
            setTimeout(() => {
              closeFilesSidebar();
              closeArchiveSidebar();
            }, 100);
            // setNotFoundError(false);
            // setBrokenLinkError(false);
            toggleLogoutBox(e);
            history("/account");
          }}
        >
          Account
        </button>
        <button
          className="st-client-logout-btns"
          disabled={loading}
          onClick={handleLogout}
        >
          {loading ? "Loading..." : "Logout"}
        </button>
      </div>
    </div>
  );
};

export default ToolsTopNav;
