import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { postData, getData } from "../../helpers/request";
import { makeSecureEncrypt, makeSecureDecrypt } from "../../helpers/security";
import { getAuthData } from "../../helpers/request";
import company_code_data from "../../archive/test_data/company_code.json";
import {
  ApiContext,
  UserContext,
  ToolTipError,
  ERROR_REGEX,
  DevelopmentContext,
} from "../../index.js";
import queryString from "query-string";
import { FaEye } from "react-icons/fa";
import {
  SignupDataContext,
  animateLoginContainer,
  RetryErrorContext,
} from "../ClientComponents";
import { toast } from "react-hot-toast";

// Importing Dummy Data
import dummy_login from "../../archive/test_data/dummy_data-v1/dummy_login.json";
import dummy_profile from "../../archive/test_data/dummy_data-v1/dummy_profile.json";
import { setProfileData } from "../../store/profileDataSlice/profileDataSlice.js";
import redirectionEnum from "../../utils/redirectionEnum.js";

// Context Values
export const loginSignupContext = React.createContext();

//Error components
const InputError = (props) => {
  return (
    <div className="client-login-input-error email-error">{props.errorMsg}</div>
  );
};

const ClientLoginForm = () => {
  const { company_code } = useParams(); //getting company code using useParams
  const [searchParams] = useSearchParams();
  // console.log(searchParams.get("assignment_id"));
  const { devEnv } = useContext(DevelopmentContext);
  const [loginBtnDisabled, setLoginBtnDisabled] = useState(false);
  const history = useNavigate();
  const { setRetryError } = useContext(RetryErrorContext);
  const API_URL = useContext(ApiContext);
  const { setProfile } = useContext(UserContext);

  // Using ref hook to capture user credentials
  const loggedClientEmail = useRef(null);
  const loggedClientPassword = useRef(null);
  const rememberClientUser = useRef(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const { setSignupData } = useContext(SignupDataContext);

  const signUpBtnRef = useRef();
  // useEffect(() => {
  //   if (sessionStorage.getItem("redirect")) {
  //     //if assignment redirection params "/assignment/as_id" is there, then remove the assignment token and let user redirect to that assignment instead
  //     const params = new URLSearchParams(window.location.search);
  //     params.delete("assignment_id");
  //     localStorage.removeItem("assignment_token");
  //     history(`${window.location.pathname}?${params.toString()}`, {
  //       replace: true,
  //     });
  //   }
  // }, []);
  useEffect(() => {
    //in case assignment id is in params
    const assignment_token = searchParams.get("assignment_id");
    if (assignment_token) {
      localStorage.setItem("assignment_token", assignment_token);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("query")) {
      localStorage.removeItem("query");
    }

    let queries = queryString.parse(document.location.search);
    setRetryError(false);

    if (queries["q"]) {
      localStorage.setItem("query", queries["q"]);
      history(window.location.href.split("?"));

      let query = localStorage.getItem("query");

      getData(`${API_URL}/api-client/client/v1/verify-account/?q=${query}`)
        .then((res) => {
          if (res.success === false) {
            if (res.message === "Your account is already verified.") {
              window.location.href = "/";
            } else {
              // toast.success(res.message);
              document.querySelector("#client-email").value = res.email;
            }
            localStorage.removeItem("query");
          } else if (res.success === true) {
            // toast.custom(() => (
            //   <div className="toast-container">
            //     <div className="toast-content">
            //       Your email has been successfully verified. Please login to
            //       continue
            //     </div>
            //   </div>
            // ));
            toast.success(
              "Your email has been successfully verified. Please login to continue.",
            );
            document.querySelector("#client-email").value = res.email;
            localStorage.removeItem("query");
          }
        })
        .catch((err) => {
          setRetryError(true);
        });
    }
  }, []);

  const accountValidation = (email, password) => {
    let valid;
    const emailRegex = /\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*/g;

    // Checking for email
    if (email === "") {
      setPasswordError(null);
      setEmailError("Enter Email Address");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setPasswordError(null);
      setEmailError("Invalid email!");
      valid = false;
    } else if (password === "") {
      setEmailError(null);
      setPasswordError("Enter Password");
      valid = false;
    } else {
      setPasswordError(null);
      setEmailError(null);
      valid = true;
    }

    return valid;
  };

  // const saveInLocalStorage = (user) => {
  //     if (rememberClientUser.current.checked){              // Storing the values in local storage if remember is checked
  //         localStorage.setItem("email",user.email);
  //         localStorage.setItem("password",user.password);
  //         localStorage.setItem("remember",rememberClientUser.current.checked)
  //     } else {                                        // Removing the values in local storage if remember is unchecked
  //         localStorage.removeItem("email");
  //         localStorage.removeItem("password");
  //         localStorage.removeItem("remember")
  //     }
  // }

  const saveToken = (token) => {
    if (rememberClientUser.current.checked) {
      // Storing the values in local storage if remember is checked
      localStorage.setItem("token", token);
      sessionStorage.setItem("token", token);
    } else {
      // Removing the values in local storage if remember is unchecked
      localStorage.removeItem("token");
      sessionStorage.setItem("token", token);
    }
  };

  const redirectPage = () => {
    // if we have a redirected url then move to that one otherwise move to this decrypt
    sessionStorage.getItem("redirect")
      ? history(sessionStorage.getItem("redirect"))
      : history("/home");
    // : history("/decrypt"); // now it redirects to the tools home page
    // clearing the session storage after redirecting
    sessionStorage.removeItem("redirect");
  };

  // Checking if there already is a user locally saved in the client browser.
  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      makeSecureDecrypt(localStorage.getItem("token"))
    ) {
      sessionStorage.setItem("token", localStorage.getItem("token"));
      redirectPage();
    }
    loggedClientEmail.current.focus();
  }, []);

  const clearInput = () => {
    // Clearing the fields
    loggedClientPassword.current.value = "";
    loggedClientEmail.current.value = "";
    // rememberClientUser.current.checked = false;
  };

  const fetchUser = () => {
    getAuthData(`${API_URL}/api-client/client/v1/profile/`)
      .then((res) => {
        if (res.success === true) {
          setProfile(res.data);
          setProfileData(res.data); // useEffect(() => {
          //   if (sessionStorage.getItem("redirect")) {
          //     //if assignment redirection params "/assignment/as_id" is there, then remove the assignment token and let user redirect to that assignment instead
          //     const params = new URLSearchParams(window.location.search);
          //     params.delete("assignment_id");
          //     localStorage.removeItem("assignment_token");
          //     history(`${window.location.pathname}?${params.toString()}`, {
          //       replace: true,
          //     });
          //   }
          // }, []);
          redirectPage();
        } else {
          setRetryError(true);
        }
      })
      .catch((err) => {
        setRetryError(true);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let data = {
      username: loggedClientEmail.current.value.toLowerCase(),
      password: loggedClientPassword.current.value,
    };

    // if user is being redirected from "ai.ez.works" to this webpage
    const assignment_token = localStorage.getItem("assignment_token");
    if (assignment_token)
      data = {
        ...data,
        assignment_key: assignment_token,
      };

    const validation = accountValidation(data.username, data.password); // validatinng the input

    if (devEnv) {
      saveToken(
        makeSecureEncrypt(JSON.stringify(dummy_login.token.access_token)),
      ); // Setting Dummy Data
      setProfile(dummy_profile.data); // Setting Dummy Data
      setProfileData(dummy_profile.data);
      redirectPage(); // Setting Dummy Data
      setLoginBtnDisabled(false);
      return;
    }

    // const signUpBtnSuccess = () => {
    //   const signUpBtn = signUpBtnRef.current;
    //   signUpBtn.style.background = "var(--light-orange,#e98742)";
    //   signUpBtn.innerText = "Logged In!";
    //   setTimeout(() => {
    //     signUpBtn.style.background = "var(--light-blue,#3cc3f2)";
    //     signUpBtn.innerText = "Login";
    //   }, 1000);
    // };

    if (validation) {
      setLoginBtnDisabled(true);
      postData(`${API_URL}/api-client/client/v1/login/`, data)
        .then((res) => {
          // document.querySelector(".client-lg-sn-animate-container").classList.add("client-lg-sn-animate-container-active");
          if (res?.success === true) {
            toast.success("Logged in successfully!", { id: "login-success" }); // useEffect(() => {
            //   if (sessionStorage.getItem("redirect")) {
            //     //if assignment redirection params "/assignment/as_id" is there, then remove the assignment token and let user redirect to that assignment instead
            //     const params = new URLSearchParams(window.location.search);
            //     params.delete("assignment_id");
            //     localStorage.removeItem("assignment_token");
            //     history(`${window.location.pathname}?${params.toString()}`, {
            //       replace: true,
            //     });
            //   }
            // }, []);
            if (res?.is_assignment_added) {
              // for ai tools redirection to specific tooltype
              const redirectionUrl = `tools/${
                redirectionEnum[res?.is_assignment_added?.tool_name]
              }/${res?.is_assignment_added?.id}`;
              if (!sessionStorage.getItem("redirect")) {
                //if there is already a redirect present then it will not overwrite it
                sessionStorage.setItem("redirect", redirectionUrl);
              }
              localStorage.removeItem("assignment_token");
            }
            // signUpBtnSuccess();
            setLoginBtnDisabled(false);
            animateLoginContainer();
            // localStorage.setItem('token', makeSecureEncrypt(JSON.stringify(res.token.access_token)));
            saveToken(
              makeSecureEncrypt(JSON.stringify(res.token.access_token)),
            );
            fetchUser();
          } else {
            // setTimeout(() => {
            //   setLoginBtnDisabled(false);
            // }, [10000]);
            // toast.error(res.message);
            if (res.message === "Incorrect Password") {
              setEmailError(null);
              setPasswordError(res.message);
            } else if (res.message === "Your account is not verified") {
              setPasswordError(null);
              setEmailError(null);
              setSignupData({ email: loggedClientEmail.current.value });
              postData(`${API_URL}/api-client/auth/v1/email-verify/`, {
                email: loggedClientEmail.current.value.toLowerCase(),
              })
                .then((res) => {
                  if (res.success === true) {
                    toast.success("Email verified successfully!");
                    history("/verifyemail");
                  } else {
                    // toast.custom(() => (
                    //   <div className="toast-error-container">
                    //     <div className="toast-content">{res.message}</div>
                    //   </div>
                    // ));
                    toast.error(res?.message || "Error!");
                  }
                })
                .catch((err) => {
                  setRetryError(true);
                  // toast.custom(() => (
                  //   <div className="toast-error-container">
                  //     <div className="toast-content">{err.message}</div>
                  //   </div>
                  // ));
                  toast.error(err?.message || "Error!");
                });
            } else if (
              res?.message === "Invalid assignment key" ||
              res?.message === "Assignment not found!"
            ) {
              toast.error(res?.message, {
                id: "invalid-key",
              });
              localStorage.removeItem("assignment_token"); // user will be able to login after 1st invalid assignment key prompt
              setEmailError(res?.message);
              setLoginBtnDisabled(false);
            } else {
              setLoginBtnDisabled(false);
              setPasswordError(null);
              setEmailError(res.message);
              clearInput();
            }
            setLoginBtnDisabled(false);
          }
        })
        .catch((err) => {
          setLoginBtnDisabled(false);
          setRetryError(true);
        });
    }
  };

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
    pswd_input.current.focus();
  };

  return (
    <form className="common-form client-login-form" onSubmit={handleLogin}>
      <div className="client-form-head">Login</div>

      <div className="login-input">
        <input
          className="client-input client-form-input"
          ref={loggedClientEmail}
          type="email"
          id="client-email"
          name="client-email"
          placeholder="Work Email"
        />
        {emailError && <InputError errorMsg={emailError} />}
      </div>

      <div className="login-input client-password-input">
        <input
          className="client-input client-form-input"
          onChange={(e) => {
            ToolTipError(e, [
              ERROR_REGEX.PASSWORD_ERROR,
              ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
            ]);
          }}
          ref={loggedClientPassword}
          type="password"
          id="client-password"
          name="client-password"
          placeholder="Password"
        />
        <span
          className="client-pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            e.target
              .closest(".client-pswd-show-btn")
              .classList.toggle("client-pswd-show-btn-active");
            showPassword(loggedClientPassword);
          }}
        >
          <FaEye />
        </span>
        {passwordError && <InputError errorMsg={passwordError} />}
      </div>

      <div className="client-login-btns-section">
        <div className="client-login-btn-part">
          <Link
            className="client-login-form-btns client-anchor"
            // to={
            //   company_code && company_code_data.includes(company_code)
            //     ? `/${company_code}/forgotpassword`
            //     : "/forgotpassword"
            // }
            to={
              searchParams.get("assignment_id")
                ? `forgotpassword/?assignment_id=${searchParams.get(
                    "assignment_id",
                  )}`
                : company_code && company_code_data.includes(company_code)
                ? `/${company_code}/forgotpassword`
                : "/forgotpassword"
            }
          >
            Forgot Password
          </Link>
          <div className="client-login-form-btns client-form-remember-check">
            <input
              className="client-form-check"
              ref={rememberClientUser}
              type="checkbox"
              id="client-remember"
              name="client-remember"
            />
            <label
              className="form-label new-form-label"
              htmlFor="client-remember"
            >
              Stay logged in
            </label>
          </div>
        </div>
        <div className="client-login-btn-part">
          <Link
            className="client-login-form-btns client-anchor"
            // to={
            //   company_code && company_code_data.includes(company_code)
            //     ? `/${company_code}/signup`
            //     : "/signup"
            // }
            to={
              searchParams.get("assignment_id")
                ? `/signup/?assignment_id=${searchParams.get("assignment_id")}`
                : company_code && company_code_data.includes(company_code)
                ? `/${company_code}/signup`
                : "/signup"
            }
          >
            Sign Up
          </Link>

          <button
            className="client-login-form-btns client-login-btn"
            type="submit"
            disabled={loginBtnDisabled}
            ref={signUpBtnRef}
          >
            {loginBtnDisabled ? "Loading..." : "Login"}
          </button>
        </div>
      </div>

      {/* <table className="client-login-bottom-form-btns-table">
                <tbody>
                    <tr>
                        <td><Link className="client-login-form-btns client-anchor" to="/forgotpassword">Forgot Password</Link></td>
                        <td>
                            <div className="client-login-form-btns client-form-remember-check">
                                <input className="client-form-check" ref={rememberClientUser} type="checkbox" id='client-remember' name='client-remember' />
                                <label className="form-label" htmlFor="client-remember">Remember Me</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><Link className="client-login-form-btns client-anchor" to="/signup">Sign Up</Link></td>
                        <td><button className="client-login-form-btns client-login-btn" type='submit'>Login</button></td>
                    </tr>
                </tbody>
            </table> */}
    </form>
  );
};

export { ClientLoginForm };
