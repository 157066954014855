export const outputReferenceConversion = (data) => {
  const combinedDataMap = {};

  data.forEach((item) => {
    const key = `${item.file_identifier}-${item.file_name}`;

    if (!combinedDataMap[key]) {
      combinedDataMap[key] = {
        file_identifier: parseInt(item.file_identifier, 10),
        file_name: item.file_name,
        page_numbers: [],
        source_text: item.source_text, // Keeping source_text from the first occurrence
      };
    }

    const pageNumber = parseInt(item.page_number, 10);
    if (!combinedDataMap[key].page_numbers.includes(pageNumber)) {
      combinedDataMap[key].page_numbers.push(pageNumber);
    }
  });
  const result = Object.values(combinedDataMap);

  // Sorting the page_numbers array in ascending order for each item
  result.forEach((item) => {
    item.page_numbers.sort((a, b) => a - b);
  });

  return result;
};
