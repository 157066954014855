import React, { useState, useContext, useEffect, useReducer } from "react";
import { makeSecureDecrypt } from "../helpers/security";
import { getAuthData } from "../helpers/request";
import { useNavigate, useParams } from "react-router-dom";
// import {LazyLoad, UserContext, navigator_info} from "../index";
import { UserContext, lazy_load_image, DevelopmentContext } from "../index";
// import {LazyLoad, UserContext, navigator_info} from "../index";
// import { useLocation } from "react-router-dom";
// CSS
import "./style/ClientDashboard.css";
import "./style/ClientLogin.css";
import company_code_data from ".././archive/test_data/company_code.json";
// CLIENT IMPORTS
import { ClientTopnav } from "./Nav/ClientTopNav/ClientTopnav";
import { ClientSidenav } from "./Nav/ClientSidenav/ClientSidenav";
import { ClientLiveDashboard } from "./Dashboard/ClientLiveDashboard/ClientLiveDashboard";
import { ClientArchiveDashboard } from "./Dashboard/ClientArchiveDashboard/ClientArchiveDashboard";
import { ClientAccountSettings } from "./Settings/ClientAccountSettings";
import { FetchPage } from "./FetchPage/FetchPage";
import { ClientSignupForm, ClientVerifyEmail } from "./Login/ClientSignup";
import { ClientLoginForm } from "./Login/ClientLogin";
import {
  ClientForgetPasswordForm,
  ClientChangePasswordForm,
  CheckForgetVerificationEmail,
} from "./Login/ClientForgetPassword";
import { ClientDashboardSidebar } from "./ClientSidebar/ClientDashboardSidebar";
import { ClientArchiveSidebar } from "./ClientSidebar/ClientArchiveSidebar";
import { RetryError } from "../error/error";

// Importing Dummy Data
// import dummy_all_assignemnts from "../archive/test_data/dummy_data-v1/dummy_all_assignments.json";
import dummy_profile from "../archive/test_data/dummy_data-v1/dummy_profile.json";

// CLIENT ERRORS
import {
  ComingSoonError,
  LoaderModal,
  NotFoundError,
  UnknownLinkError,
} from "./ClientError/ClientError";

// MODALS
import { DecryptFileModal } from "./Modal/DecryptFileModal/DecryptFileModal";
import { EncryptedAssignmentModal } from "./Modal/EncryptedAssignmentModal/EncryptedAssignmentModal";
import { ClientHelp } from "./ClientHelp/ClientHelp";
import { SharingModal } from "./Modal/SharingModal/SharingModal";
import { SourceFileDownloadModal } from "./Modal/SourceFileDownloadModal/SourceFileDownloadModal";
import { DeclineRequestModal } from "./Modal/DeclineRequestModal/DeclineRequestModal";
import { RevokeRequestModal } from "./Modal/RevokeRequestModal/RevokeRequestModal";
import { ShareWarningModal } from "./Modal/ShareWarningModal/ShareWarningModal";
import { ShareRevokeModal } from "./Modal/ShareRevokeModal/ShareRevokeModal";
import { RevokeRequestErrorModal } from "./Modal/RevokeRequestErrorModal/RevokeRequestErrorModal";
import {
  setCompanyCode,
  setProfileData,
} from "../store/profileDataSlice/profileDataSlice";
import { useDispatch } from "react-redux";
import LoginModule from "../LoginModule/LoginModule";
// import { useGetPerformanceReport } from "../hooks/useGetPerformanceReport";

export const ClientArchiveFilesArrayContext = React.createContext();
export const ClientArchiveFileContext = React.createContext();
export const ClientArchiveCountContext = React.createContext();
export const ClientFilesArrayContext = React.createContext();
export const ClientDashboardFileContext = React.createContext();
export const ModalDispatchContext = React.createContext(); // Stores the information about which modal is to be shown
export const SignupDataContext = React.createContext();
export const ForgetMailSentContext = React.createContext();
export const NewFileContext = React.createContext();
export const NotFoundErrorContext = React.createContext();
export const ComingSoonErrorContext = React.createContext();
export const LoaderContext = React.createContext();
export const RetryErrorContext = React.createContext();
export const BrokenLinkErrorContext = React.createContext();
export const ClientRouteContext = React.createContext(); // Required to see which tab is active in sidenav and also to set active tab in mobile sidebar
export const ClientSharingLogContext = React.createContext();
export const ClientAccessLogContext = React.createContext();
export const ClientArchiveSharingLogContext = React.createContext();
export const ClientArchiveAccessLogContext = React.createContext();
export const EncryptedContext = React.createContext(); // Manages the file encryption stage

// ============ DASHBAORD RELATED STUFF ====================

// DASHBOARD ENCRYPTION STATE
export const FILE_ENCRYPTION_STATES = {
  DOWNLOAD: "download",
  DECRYPTED: "decrypted",
  ENCRYPTED: "encrypted",
  OLD_FILE: "old_file",
  REVOKED: "revoked",
};

// ================== HIDING THE RESPONSIVE SIDEBARS =====================
export const hideFilesSidebar = () => {
  document
    .querySelector("#client-file-sidebar")
    ?.classList.remove("client-sidebar-show");
  document
    .querySelector(".client-account-sidebar")
    ?.classList.remove("client-sidebar-show");
};

// export const ERROR_REGEX = {
//   // SYMBOLS_ERROR : {REGEX:"[^0-9|^a-z|^A-Z| |.]",ERROR_MSG:"Symbols not allowed",VALIDATE:true},
//   SYMBOLS_ERROR : {REGEX:"[~;\"<>|,+=?\\\\(\)\{\}\\[\\]]",ERROR_MSG:"Symbols not allowed",VALIDATE:true},
//   TEXT_ERROR : {REGEX:"[a-z]",ERROR_MSG:"Text not allowed",VALIDATE:true},
//   PASSWORD_ERROR : {REGEX:"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",ERROR_MSG:"Please enter an 8 digit password that includes \nat least 1 uppercase, 1 lowercase, 1 number and \n1 special character ( ! , @ , # , $ , & , * , ( , ) , + ).",VALIDATE:false},
// }

// // ====================== TOOLTIP ERRORs ==================================
// export const ToolTipError = (e,pattern) => {
//   const re = new RegExp(pattern.REGEX);
//   if (pattern.VALIDATE){
//     e.target.setCustomValidity((re.test(e.target.value)) ? pattern.ERROR_MSG : "");
//   } else {
//     e.target.setCustomValidity((!re.test(e.target.value)) ? pattern.ERROR_MSG : "");
//   }
// }

// ================== MODAL COMPONENT AND REQUIRED FUCNTIONS =============
const Modal = ({ type, modalDispatch }) => {
  const modal_dispatch = useContext(ModalDispatchContext);

  switch (type.modal_show_type) {
    case CLIENT_MODAL_TYPE.DECRYPT_FILE_MODAL:
      return (
        <section
          className="client-modal"
          onClick={(e) => {
            e.target.className === "client-modal" &&
              modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
          }}
        >
          <DecryptFileModal />
        </section>
      );
    case CLIENT_MODAL_TYPE.ENCRYPTED_ASSIGNMENT:
      return (
        <section className="client-modal glass-container">
          <EncryptedAssignmentModal />
        </section>
      );
    case CLIENT_MODAL_TYPE.DECLINE_REQUEST:
      return (
        <section className="client-modal glass-container">
          <DeclineRequestModal payload={type.payload} />
        </section>
      );
    case CLIENT_MODAL_TYPE.REVOKE_REQUEST:
      return (
        <section className="client-modal glass-container">
          <RevokeRequestModal payload={type.payload} />
        </section>
      );
    case CLIENT_MODAL_TYPE.REVOKE_REQUEST_ERROR:
      return (
        <section className="client-modal glass-container">
          <RevokeRequestErrorModal />
        </section>
      );
    case CLIENT_MODAL_TYPE.SHARE_MODAL:
      return (
        <section className="client-modal glass-container">
          <SharingModal payload={type.payload} />
        </section>
      );
    case CLIENT_MODAL_TYPE.SHARE_REVOKE:
      return (
        <section className="client-modal glass-container">
          <ShareRevokeModal payload={type.payload} />
        </section>
      );
    case CLIENT_MODAL_TYPE.SHARE_WARNING:
      return (
        <section className="client-modal glass-container">
          <ShareWarningModal payload={type.payload} />
        </section>
      );
    case CLIENT_MODAL_TYPE.SOURCE_FILE_MODAL:
      return (
        <section className="client-modal glass-container">
          <SourceFileDownloadModal />
        </section>
      );
    default:
      return <></>;
  }
};

export const CLIENT_MODAL_TYPE = {
  DECRYPT_FILE_MODAL: "decrypt-modal",
  ENCRYPTED_ASSIGNMENT: "encrypted_assignment",
  SOURCE_FILE_MODAL: "source-file-download-modal",
  SHARE_MODAL: "share-modal", // default sharing modal
  SHARE_REVOKE: "share-revoke", //revoke sharing modal
  SHARE_WARNING: "share-warning", //share warning modal
  DECLINE_REQUEST: "decline-request",
  REVOKE_REQUEST: "revoke-request",
  REVOKE_REQUEST_ERROR: "revoke-request-error",
  NONE: "none",
};

const modalReducer = (state, action) => {
  switch (action.modal_show_type) {
    case CLIENT_MODAL_TYPE.NONE:
      return { modal_show_type: CLIENT_MODAL_TYPE.NONE };
    case CLIENT_MODAL_TYPE.DECRYPT_FILE_MODAL:
      return { modal_show_type: CLIENT_MODAL_TYPE.DECRYPT_FILE_MODAL };
    case CLIENT_MODAL_TYPE.ENCRYPTED_ASSIGNMENT:
      return { modal_show_type: CLIENT_MODAL_TYPE.ENCRYPTED_ASSIGNMENT };
    case CLIENT_MODAL_TYPE.DECLINE_REQUEST:
      return {
        modal_show_type: CLIENT_MODAL_TYPE.DECLINE_REQUEST,
        payload: action.payload,
      };
    case CLIENT_MODAL_TYPE.REVOKE_REQUEST:
      return {
        modal_show_type: CLIENT_MODAL_TYPE.REVOKE_REQUEST,
        payload: action.payload,
      };
    case CLIENT_MODAL_TYPE.SHARE_MODAL: //default sharing modal
      return {
        modal_show_type: CLIENT_MODAL_TYPE.SHARE_MODAL,
        payload: action.payload,
      };
    case CLIENT_MODAL_TYPE.SHARE_REVOKE: //revoke sharing modal
      return {
        modal_show_type: CLIENT_MODAL_TYPE.SHARE_REVOKE,
        payload: action.payload,
      };
    case CLIENT_MODAL_TYPE.SHARE_WARNING: //share warning modal
      return {
        modal_show_type: CLIENT_MODAL_TYPE.SHARE_WARNING,
        payload: action.payload,
      };
    case CLIENT_MODAL_TYPE.REVOKE_REQUEST_ERROR:
      return { modal_show_type: CLIENT_MODAL_TYPE.REVOKE_REQUEST_ERROR };
    case CLIENT_MODAL_TYPE.SOURCE_FILE_MODAL:
      return { modal_show_type: CLIENT_MODAL_TYPE.SOURCE_FILE_MODAL };
    default:
      return state;
  }
};

// ==================== LOGIN/SIGNUP FOOTER ==============================
const ClientLoginFooter = () => {
  return (
    <div className="client-login-footer">
      <div className="client-login-footer-item">
        <a
          className="client-login-footer-text client-login-footer-text-left"
          target="_blank"
          href="https://ez.works/privacy-policy"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <span className="client-login-footer-separator"></span>
        <a
          className="client-login-footer-text client-login-footer-text-right"
          target="_blank"
          href="https://ez.works/terms-of-use"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </div>

      <span className="client-login-footer-separator" id="hidden-dot"></span>

      <div className="client-login-footer-item">
        <a
          className="client-login-footer-text client-login-footer-text-left"
          target="_blank"
          href="https://www.ez.works/ez/information-security-and-data-privacy"
          rel="noreferrer"
        >
          ISO 27001:2013
        </a>
        <span className="client-login-footer-separator"></span>
        <a
          className="client-login-footer-text client-login-footer-text-right"
          target="_blank"
          href="https://ez.works/ez/general-data-protection-regulation"
          rel="noreferrer"
        >
          About GDPR
        </a>
      </div>
    </div>
  );
};

const ClientLoginImage = () => {
  const { company_code } = useParams();

  return (
    <div className="client-lg-sn-img-container">
      {/* <div className="client-login-text">
        More <span style={{color:"var(--orange,#ea7b2c)"}}>Secure</span> than Email
      </div> */}
      <div className="client-login-background">
        <LoginRedirectButtons />
        {company_code && company_code_data.includes(company_code) && (
          <div className="company_icon_container">
            <img
              src={`/resources/companyLogos/CompanyCode_${company_code}.svg`}
              // src={`/resources/companyLogos/CompanyCode_mckd.svg`}
              alt="company code"
            ></img>
            <span className="discount_text">
              Team & Alumni get special discounts
            </span>
          </div>
        )}
        <img
          className="client-login-background-img lazy-img"
          id="client-login-background-desktop"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/resources/backgrounds/login-bg.jpg"}
          alt="EZ-Secure Transfer"
        />
        <img
          className="client-login-background-img lazy-img"
          id="client-login-background-tablet"
          onLoad={lazy_load_image}
          src={
            process.env.PUBLIC_URL + "/resources/backgrounds/login-bg-500p.jpg"
          }
          alt="EZ-Secure Transfer"
        />
        <img
          className="client-login-background-img lazy-img"
          id="client-login-background-mobile"
          onLoad={lazy_load_image}
          src={
            process.env.PUBLIC_URL + "/resources/backgrounds/login-bg-500p.jpg"
          }
          alt="EZ-Secure Transfer"
        />
      </div>
      {/* <LazyLoad><div className="client-login-background"></div></LazyLoad> */}
      {/* <img src="login_background.jpg"></img> */}
    </div>
  );
};

// ===================== CLIENT COMPONENTS ===============================
export const animateLoginContainer = () => {
  document
    .querySelector(".client-lg-sn-animate-container")
    .classList.add("client-lg-sn-animate-container-active");
};

export const EzAccessComponent = () => {
  return (
    <div className=" client-solo-component">
      <img
        className="lazy-img mobile-ez-logo"
        onLoad={lazy_load_image}
        src={process.env.PUBLIC_URL + "/resources/login_icons/ez_logo.png"}
        alt="EZ Works"
      />
      <span className="client-form-head client-access-head client-mobile-head">
        Access
      </span>
    </div>
  );
};
export const LoginRedirectButtons = () => {
  return (
    <section className="login_redirect_container">
      <a
        className="new_login_btn"
        href={`${process.env.REACT_APP_WEBSITE_URL}/?io`}
        // target="_blank"
        // rel="noreferrer"
      >
        <img
          className="login_logo_new"
          src="/resources/LoginIcons/70+serviceslogo.svg"
          alt="70 icons"
        ></img>
        {/* <img
          className="login_icon_new"
          src="/resources/LoginIcons/servicesLogo.svg"
          alt="services"
        ></img> */}
      </a>
      <a
        className="new_login_btn"
        href={`${process.env.REACT_APP_AI_TOOLS}`}
        // target="_blank"
        // rel="noreferrer"
      >
        <img
          className="login_logo_new"
          src="/resources/LoginIcons/aitoolslogo.svg"
          alt=" ai tools"
        ></img>
        {/* <img
          className="login_icon_new"
          src="/resources/LoginIcons/toolsLogo.svg"
          alt=" tools logo"
        ></img> */}
      </a>
      <div className="new_login_btn">
        <img
          className="login_logo_new"
          src="/resources/LoginIcons/ezaccesslogo.svg"
          alt="io logo"
        ></img>
        {/* <img
          className="login_icon_new"
          src="/resources/LoginIcons/newLoginLogo.svg"
          alt="login logo"
        ></img> */}
      </div>
    </section>
  );
};
const ClientLoginComponents = ({ page }) => {
  const dispatch = useDispatch();
  const [signupData, setSignupData] = useState(null);
  const [forgetMailSent, setForgetMailSent] = useState(false);
  const { company_code } = useParams();
  // const [feedDataLoaded] = useState(false);

  // useGetPerformanceReport({
  //   screenName: "Feed",
  //   isLoading: feedDataLoaded,
  // });

  //error states
  const [retryError, setRetryError] = useState(false);

  // setting company code
  useEffect(() => {
    if (!company_code_data.includes(company_code)) return; //verifying if the company is listed
    dispatch(setCompanyCode(company_code));
  }, []);

  switch (page) {
    case "login":
      return (
        <RetryErrorContext.Provider value={{ retryError, setRetryError }}>
          <SignupDataContext.Provider value={{ signupData, setSignupData }}>
            {retryError ? (
              <RetryError />
            ) : (
              <>
                <div className="client-login-signup">
                  <div className="client-lg-sn-animate-container"></div>
                  <div className="client-brand client-brand-access">
                    <img
                      className="lazy-img"
                      onLoad={lazy_load_image}
                      src={
                        process.env.PUBLIC_URL +
                        "/resources/login_icons/ez_logo.png"
                      }
                      alt="EZ Works"
                    />
                    <span className="client-form-head client-access-head">
                      Access
                    </span>
                  </div>
                  <div className="client-lg-sn-form-container">
                    <EzAccessComponent />

                    <ClientLoginForm />
                    <ClientLoginFooter />
                  </div>
                  <ClientLoginImage />
                  <div className="rights-reserved">
                    © EZ Lab Private Limited 2021. All Rights Reserved.
                  </div>
                </div>
                <LoginModule />
              </>
            )}
          </SignupDataContext.Provider>
        </RetryErrorContext.Provider>
      );

    case "signup":
      return (
        <RetryErrorContext.Provider value={{ retryError, setRetryError }}>
          <SignupDataContext.Provider value={{ signupData, setSignupData }}>
            {retryError ? (
              <RetryError />
            ) : (
              <>
                <div className="client-login-signup">
                  <div className="client-lg-sn-animate-container"></div>
                  <div className="client-brand client-brand-access">
                    <img
                      className="lazy-img"
                      onLoad={lazy_load_image}
                      src={
                        process.env.PUBLIC_URL +
                        "/resources/login_icons/ez_logo.png"
                      }
                      alt="EZ Works"
                    />
                    <span className="client-form-head client-access-head">
                      Access
                    </span>
                  </div>
                  <div className="client-lg-sn-form-container">
                    <EzAccessComponent />
                    <ClientSignupForm />
                    <ClientLoginFooter />
                  </div>
                  <ClientLoginImage />
                  <div className="rights-reserved">
                    © EZ Lab Private Limited 2021. All Rights Reserved.
                  </div>
                </div>
                <LoginModule />
              </>
            )}
          </SignupDataContext.Provider>
        </RetryErrorContext.Provider>
      );

    case "clientforgetpassword":
      return (
        <RetryErrorContext.Provider value={{ retryError, setRetryError }}>
          <ForgetMailSentContext.Provider
            value={{ forgetMailSent, setForgetMailSent }}
          >
            {retryError ? (
              <RetryError />
            ) : (
              <>
                <div className="client-login-signup">
                  <div className="client-lg-sn-animate-container"></div>
                  <div className="client-brand client-brand-access">
                    <img
                      className="lazy-img"
                      onLoad={lazy_load_image}
                      src={
                        process.env.PUBLIC_URL +
                        "/resources/login_icons/ez_logo.png"
                      }
                      alt="EZ Works"
                    />
                    <span className="client-form-head client-access-head">
                      Access
                    </span>
                  </div>
                  <div className="client-lg-sn-form-container">
                    {forgetMailSent ? (
                      <CheckForgetVerificationEmail />
                    ) : (
                      <ClientForgetPasswordForm />
                    )}
                    <ClientLoginFooter />
                  </div>
                  <ClientLoginImage />
                  <div className="rights-reserved">
                    © EZ Lab Private Limited 2021. All Rights Reserved.
                  </div>
                </div>
                <LoginModule />
              </>
            )}
          </ForgetMailSentContext.Provider>
        </RetryErrorContext.Provider>
      );

    case "clientforgetpasswordtoken":
      return (
        <RetryErrorContext.Provider value={{ retryError, setRetryError }}>
          {retryError ? (
            <RetryError />
          ) : (
            <>
              <div className="client-login-signup">
                <div className="client-lg-sn-animate-container"></div>
                <div className="client-brand client-brand-access">
                  <img
                    className="lazy-img"
                    onLoad={lazy_load_image}
                    src={
                      process.env.PUBLIC_URL +
                      "/resources/login_icons/ez_logo.png"
                    }
                    alt="EZ Works"
                  />
                  <span className="client-form-head client-access-head">
                    Access
                  </span>
                </div>
                <div className="client-lg-sn-form-container">
                  <ClientChangePasswordForm />
                  <ClientLoginFooter />
                </div>
                <ClientLoginImage />
                <div className="rights-reserved">
                  © EZ Lab Private Limited 2021. All Rights Reserved.
                </div>
              </div>
              <LoginModule />
            </>
          )}
        </RetryErrorContext.Provider>
      );

    case "verifyemail":
      return (
        <RetryErrorContext.Provider value={{ retryError, setRetryError }}>
          <SignupDataContext.Provider value={{ signupData, setSignupData }}>
            {retryError ? (
              <RetryError />
            ) : (
              <>
                <div className="client-login-signup">
                  <div className="client-lg-sn-animate-container"></div>
                  <div className="client-brand client-brand-access">
                    <img
                      className="lazy-img"
                      onLoad={lazy_load_image}
                      src={
                        process.env.PUBLIC_URL +
                        "/resources/login_icons/ez_logo.png"
                      }
                      alt="EZ Works"
                    />
                    <span className="client-form-head client-access-head">
                      Access
                    </span>
                  </div>
                  <div className="client-lg-sn-form-container">
                    <ClientVerifyEmail />
                    <ClientLoginFooter />
                  </div>
                  <ClientLoginImage />
                  <div className="rights-reserved">
                    © EZ Lab Private Limited 2021. All Rights Reserved.
                  </div>
                </div>
                <LoginModule />
              </>
            )}
          </SignupDataContext.Provider>
        </RetryErrorContext.Provider>
      );

    default:
      return (
        <RetryErrorContext.Provider value={{ retryError, setRetryError }}>
          <SignupDataContext.Provider value={{ signupData, setSignupData }}>
            {retryError ? (
              <RetryError />
            ) : (
              <>
                <div className="client-login-signup">
                  <div className="client-lg-sn-animate-container"></div>
                  <div className="client-brand client-brand-access">
                    <img
                      className="lazy-img"
                      onLoad={lazy_load_image}
                      src={
                        process.env.PUBLIC_URL +
                        "/resources/login_icons/ez_logo.png"
                      }
                      alt="EZ Works"
                    />
                    <span className="client-form-head client-access-head">
                      Access
                    </span>
                  </div>
                  <div className="client-lg-sn-form-container">
                    <ClientLoginForm />
                    <ClientLoginFooter />
                  </div>
                  <ClientLoginImage />
                  <div className="rights-reserved">
                    © EZ Lab Private Limited 2021. All Rights Reserved.
                  </div>
                </div>
                <LoginModule />
              </>
            )}
          </SignupDataContext.Provider>
        </RetryErrorContext.Provider>
      );
  }
};

// ==================== VIDEO Code ======================================
export const video_controls = {
  play: "play",
  pause: "pause",
  stop: "stop",
};

const video_resolutions = {
  DESKTOP: "./resources/backgrounds/fetch_animation.mp4",
  MOBILE: "./resources/backgrounds/fetch_animation_500p.mp4",
};

export const VideoControls = (command) => {
  const video = document.querySelector("#fetch-animation");
  switch (command) {
    case video_controls.play:
      video.play();
      break;
    case video_controls.pause:
      video.pause();
      break;
    default:
      break;
  }
  return video;
};

// ROUTES
export const CLIENT_ROUTES = {
  DECRYPT: "decrypt",
  ASSIGNMENTS: "assignments",
  ARCHIVE: "archive",
  ACCOUNT: "account",
  FAQ: "faq",
};

const ClientPageComponents = ({ page }) => {
  const { devEnv } = useContext(DevelopmentContext);
  const { setProfile } = useContext(UserContext);
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    modal_show_type: CLIENT_MODAL_TYPE.NONE,
  }); // Stores information about which modal is to be shown

  const [clientFilesArray, setClientFilesArray] = useState([]); // Saving array state from fetch
  const [clientDisplayedFile, setClientDisplayedFile] = useState({}); // Saving dashboard display state

  const [encryptedState, setEncryptedState] = useState(null);

  const [clientArchiveFilesArray, setClientArchiveFilesArray] = useState(null); // Saving array state from fetch
  const [clientArchiveFile, setClientArchiveFile] = useState({}); // Saving dashboard display state
  const [clientArchiveCount, setClientArchiveCount] = useState(null);
  const [sharedLogData, setSharedLogData] = useState(null);
  const [accessLogData, setAccessLogData] = useState(null);
  // const [archiveSharedLogData, setArchiveSharedLogData] = useState(null);
  // const [archiveAccessLogData, setArchiveAccessLogData] = useState(null);
  const [loader, setLoader] = useState(null); // loader implemented for future use.

  // const [routeState, setRouteState] = useState(null);

  const [newFile, setNewFile] = useState(null);

  //error states
  const [notFoundError, setNotFoundError] = useState(false);
  const [comingSoonError, setCommingSoonError] = useState(false);
  const [brokenLinkError, setBrokenLinkError] = useState(false);

  // Fetch page animation video
  const [animationVideo, setAnimationVideo] = useState(
    video_resolutions.DESKTOP,
  );

  // Getting the account
  const fetchClient = () => {
    if (devEnv) {
      setProfile(dummy_profile.data); // Setting Dummy Data
      setProfileData(dummy_profile.data);
      return;
    }

    if (
      sessionStorage.getItem("token") &&
      makeSecureDecrypt(sessionStorage.getItem("token"))
    ) {
      getAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/client/v1/profile/`,
      )
        .then((res) => {
          if (res.success === true) {
            setProfile(res.data);
            setProfileData(res.data);
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  // const getAssignments = () => {
  //   if (devEnv) {
  //     setClientFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
  //     // setClientFilesArray(dummy_all_external_assignments.data) // Setting External assignments Dummy Data
  //     return;
  //   }

  //   getAuthData(
  //     `${process.env.REACT_APP_API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`,
  //   )
  //     .then((res) => {
  //       if (res.success === true) {
  //         setClientFilesArray(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.error(err);
  //       setNotFoundError(true);
  //     });
  // };

  // ####################Now we are loading all the assignments from archive sidebar#########################

  // const getArchiveAssignments = () => {
  //   getAuthData(`${process.env.REACT_APP_API_URL}/api-client/archive/v1/client-all-assignments/?id=${clientArchiveFile?.id}`)
  //   .then((res) => {
  //     if (res.success === true) {
  //       // setClientArchiveFilesArray(res.data);
  //       setClientArchiveFile(res.last_assignment);
  //     }
  //   })
  //   .catch(err => {
  //     // console.error(err);
  //     setNotFoundError(true);
  //   })
  // }

  const responsiveVideo = () => {
    if (window.innerHeight > window.innerWidth) {
      setAnimationVideo(video_resolutions.MOBILE);
    } else {
      setAnimationVideo(video_resolutions.DESKTOP);
    }
  };

  const orientationCheck = () => {
    window.addEventListener(
      "orientationchange",
      function () {
        responsiveVideo();
        // Reloading the page upon orientation change
        window.location.reload();
      },
      false,
    );
  };

  // const redirectLocation = () => {
  //   // // setting the redirect url if there is a particular url entered into the url bar so that after login we can be redirected to that url
  //   // redirectLocation();
  //   if (sessionStorage.getItem('token') && makeSecureDecrypt(sessionStorage.getItem('token'))){
  //     console.log('here')
  //   } else {
  //     sessionStorage.setItem("redirect",window.location.pathanme)
  //   }
  // }

  const navigate = useNavigate();

  const checkToken = () => {
    if (
      sessionStorage.getItem("token") &&
      makeSecureDecrypt(sessionStorage.getItem("token"))
    ) {
      return;
    } else if (page) {
      if (!sessionStorage.getItem("redirect")) {
        sessionStorage.setItem("redirect", window.location.pathname);
      }
      navigate("/");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    // chekcing token validation
    checkToken();
    // adding token to session storage when a component page is loaded and local storage already has the tokken saved for the client
    localStorage.getItem("token") &&
      makeSecureDecrypt(localStorage.getItem("token")) &&
      sessionStorage.setItem("token", localStorage.getItem("token"));
    // getting the client profile
    fetchClient();
    // getting assignments list for sidebar
    // getAssignments();
    // getting archived assignment data to be loaded initailly in sidebar and archive page
    // getArchiveAssignments()
    // checking the orientation
    orientationCheck();
    // load video
    responsiveVideo();
  }, []);

  const hideLogoutbox = (e) => {
    if (
      !e.target.closest(".client-user") &&
      !e.target.closest(".client-logout-box")
    ) {
      document.querySelector(".client-logout-box").style.display = "none";
    }
  };

  if (
    sessionStorage.getItem("token") &&
    makeSecureDecrypt(sessionStorage.getItem("token"))
  ) {
    switch (page) {
      case CLIENT_ROUTES.DECRYPT:
        return (
          <BrokenLinkErrorContext.Provider
            value={{ brokenLinkError, setBrokenLinkError }}
          >
            <ComingSoonErrorContext.Provider
              value={{ comingSoonError, setCommingSoonError }}
            >
              <NotFoundErrorContext.Provider
                value={{ notFoundError, setNotFoundError }}
              >
                <NewFileContext.Provider value={{ newFile, setNewFile }}>
                  <ModalDispatchContext.Provider value={modalDispatch}>
                    <ClientFilesArrayContext.Provider
                      value={{ clientFilesArray, setClientFilesArray }}
                    >
                      <ClientDashboardFileContext.Provider
                        value={{ clientDisplayedFile, setClientDisplayedFile }}
                      >
                        <ClientArchiveFilesArrayContext.Provider
                          value={{
                            clientArchiveFilesArray,
                            setClientArchiveFilesArray,
                          }}
                        >
                          <ClientArchiveFileContext.Provider
                            value={{ clientArchiveFile, setClientArchiveFile }}
                          >
                            <ClientArchiveCountContext.Provider
                              value={{
                                clientArchiveCount,
                                setClientArchiveCount,
                              }}
                            >
                              <ClientRouteContext.Provider
                                value={CLIENT_ROUTES.DECRYPT}
                              >
                                <LoaderContext.Provider
                                  value={{ loader, setLoader }}
                                >
                                  <Modal type={modalState} />
                                  {loader && <LoaderModal errMsg={loader} />}
                                  <div
                                    className="client-dashboard-container"
                                    onClick={(e) => {
                                      hideLogoutbox(e);
                                    }}
                                  >
                                    <div className="client-dashboard-background-container">
                                      <div className="client-dashboard-background-gradient"></div>
                                    </div>
                                    <div className="fetch-animation-container">
                                      <video
                                        id="fetch-animation"
                                        src={animationVideo}
                                        muted
                                        playsInline
                                      >
                                        Your browser does not support HTML
                                        video.
                                      </video>
                                    </div>
                                    <ClientTopnav />
                                    <ClientSidenav />
                                    {notFoundError && <NotFoundError />}
                                    {brokenLinkError && <UnknownLinkError />}
                                    {comingSoonError && <ComingSoonError />}
                                    {!notFoundError &&
                                      !comingSoonError &&
                                      !brokenLinkError && (
                                        <>
                                          {window.matchMedia(
                                            "(min-width: 300px) and (max-width: 600px)",
                                          ).matches && (
                                            <ClientArchiveSidebar />
                                          )}{" "}
                                          {/*We need the sidebar here coz it will be one to be displayed in the responsive design */}
                                          {window.matchMedia(
                                            "(min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px) , (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px) , (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px)",
                                          ).matches && (
                                            <ClientArchiveSidebar />
                                          )}{" "}
                                          {/* FOR IPAD */}
                                          <ClientDashboardSidebar />
                                          <FetchPage />
                                        </>
                                      )}
                                  </div>
                                </LoaderContext.Provider>
                              </ClientRouteContext.Provider>
                            </ClientArchiveCountContext.Provider>
                          </ClientArchiveFileContext.Provider>
                        </ClientArchiveFilesArrayContext.Provider>
                      </ClientDashboardFileContext.Provider>
                    </ClientFilesArrayContext.Provider>
                  </ModalDispatchContext.Provider>
                </NewFileContext.Provider>
              </NotFoundErrorContext.Provider>
            </ComingSoonErrorContext.Provider>
          </BrokenLinkErrorContext.Provider>
        );
      case CLIENT_ROUTES.ASSIGNMENTS:
        return (
          <BrokenLinkErrorContext.Provider
            value={{ brokenLinkError, setBrokenLinkError }}
          >
            <ComingSoonErrorContext.Provider
              value={{ comingSoonError, setCommingSoonError }}
            >
              <NotFoundErrorContext.Provider
                value={{ notFoundError, setNotFoundError }}
              >
                <NewFileContext.Provider value={{ newFile, setNewFile }}>
                  <ModalDispatchContext.Provider value={modalDispatch}>
                    <ClientFilesArrayContext.Provider
                      value={{ clientFilesArray, setClientFilesArray }}
                    >
                      <ClientDashboardFileContext.Provider
                        value={{ clientDisplayedFile, setClientDisplayedFile }}
                      >
                        <ClientArchiveFilesArrayContext.Provider
                          value={{
                            clientArchiveFilesArray,
                            setClientArchiveFilesArray,
                          }}
                        >
                          <ClientArchiveFileContext.Provider
                            value={{ clientArchiveFile, setClientArchiveFile }}
                          >
                            <ClientArchiveCountContext.Provider
                              value={{
                                clientArchiveCount,
                                setClientArchiveCount,
                              }}
                            >
                              <ClientSharingLogContext.Provider
                                value={{ sharedLogData, setSharedLogData }}
                              >
                                <ClientAccessLogContext.Provider
                                  value={{ accessLogData, setAccessLogData }}
                                >
                                  <ClientRouteContext.Provider
                                    value={CLIENT_ROUTES.ASSIGNMENTS}
                                  >
                                    <EncryptedContext.Provider
                                      value={{
                                        encryptedState,
                                        setEncryptedState,
                                      }}
                                    >
                                      <LoaderContext.Provider
                                        value={{ loader, setLoader }}
                                      >
                                        <Modal type={modalState} />
                                        {loader && (
                                          <LoaderModal errMsg={loader} />
                                        )}
                                        <div
                                          className="client-dashboard-container"
                                          onClick={(e) => {
                                            hideLogoutbox(e);
                                          }}
                                        >
                                          <div className="client-dashboard-background-container">
                                            <div className="client-dashboard-background-image"></div>
                                          </div>
                                          <ClientTopnav />
                                          <ClientSidenav />
                                          {notFoundError && <NotFoundError />}
                                          {brokenLinkError && (
                                            <UnknownLinkError />
                                          )}
                                          {comingSoonError && (
                                            <ComingSoonError />
                                          )}
                                          {!notFoundError &&
                                            !comingSoonError &&
                                            !brokenLinkError && (
                                              <>
                                                {window.matchMedia(
                                                  "(min-width: 300px) and (max-width: 600px)",
                                                ).matches && (
                                                  <ClientArchiveSidebar />
                                                )}{" "}
                                                {/*We need the sidebar here coz it will be one to be displayed in the responsive design */}
                                                {window.matchMedia(
                                                  "(min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px) , (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px) , (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px)",
                                                ).matches && (
                                                  <ClientArchiveSidebar />
                                                )}{" "}
                                                {/* FOR IPAD */}
                                                <ClientDashboardSidebar />
                                                <ClientLiveDashboard />
                                              </>
                                            )}
                                        </div>
                                      </LoaderContext.Provider>
                                    </EncryptedContext.Provider>
                                  </ClientRouteContext.Provider>
                                </ClientAccessLogContext.Provider>
                              </ClientSharingLogContext.Provider>
                            </ClientArchiveCountContext.Provider>
                          </ClientArchiveFileContext.Provider>
                        </ClientArchiveFilesArrayContext.Provider>
                      </ClientDashboardFileContext.Provider>
                    </ClientFilesArrayContext.Provider>
                  </ModalDispatchContext.Provider>
                </NewFileContext.Provider>
              </NotFoundErrorContext.Provider>
            </ComingSoonErrorContext.Provider>
          </BrokenLinkErrorContext.Provider>
        );
      case CLIENT_ROUTES.ARCHIVE:
        return (
          <BrokenLinkErrorContext.Provider
            value={{ brokenLinkError, setBrokenLinkError }}
          >
            <ComingSoonErrorContext.Provider
              value={{ comingSoonError, setCommingSoonError }}
            >
              <NotFoundErrorContext.Provider
                value={{ notFoundError, setNotFoundError }}
              >
                <NewFileContext.Provider value={{ newFile, setNewFile }}>
                  <ModalDispatchContext.Provider value={modalDispatch}>
                    <ClientFilesArrayContext.Provider
                      value={{ clientFilesArray, setClientFilesArray }}
                    >
                      <ClientDashboardFileContext.Provider
                        value={{ clientDisplayedFile, setClientDisplayedFile }}
                      >
                        <ClientArchiveFilesArrayContext.Provider
                          value={{
                            clientArchiveFilesArray,
                            setClientArchiveFilesArray,
                          }}
                        >
                          <ClientArchiveFileContext.Provider
                            value={{ clientArchiveFile, setClientArchiveFile }}
                          >
                            <ClientArchiveCountContext.Provider
                              value={{
                                clientArchiveCount,
                                setClientArchiveCount,
                              }}
                            >
                              <ClientRouteContext.Provider
                                value={CLIENT_ROUTES.ARCHIVE}
                              >
                                {/* <ClientArchiveSharingLogContext.Provider value={{archiveSharedLogData, setArchiveSharedLogData}}>
                    <ClientArchiveAccessLogContext.Provider value={{archiveAccessLogData, setArchiveAccessLogData}}> */}
                                <ClientSharingLogContext.Provider
                                  value={{ sharedLogData, setSharedLogData }}
                                >
                                  <ClientAccessLogContext.Provider
                                    value={{ accessLogData, setAccessLogData }}
                                  >
                                    <EncryptedContext.Provider
                                      value={{
                                        encryptedState,
                                        setEncryptedState,
                                      }}
                                    >
                                      <LoaderContext.Provider
                                        value={{ loader, setLoader }}
                                      >
                                        <Modal type={modalState} />
                                        {loader && (
                                          <LoaderModal errMsg={loader} />
                                        )}
                                        <div
                                          className="client-dashboard-container"
                                          onClick={(e) => {
                                            hideLogoutbox(e);
                                          }}
                                        >
                                          <div className="client-dashboard-background-container">
                                            <div className="client-dashboard-background-image"></div>
                                          </div>
                                          <ClientTopnav />
                                          <ClientSidenav />
                                          {notFoundError && <NotFoundError />}
                                          {brokenLinkError && (
                                            <UnknownLinkError />
                                          )}
                                          {comingSoonError && (
                                            <ComingSoonError />
                                          )}
                                          {!notFoundError &&
                                            !comingSoonError &&
                                            !brokenLinkError && (
                                              <>
                                                {window.matchMedia(
                                                  "(min-width: 300px) and (max-width: 600px)",
                                                ).matches && (
                                                  <ClientDashboardSidebar />
                                                )}{" "}
                                                {/*We need the sidebar here coz it will be one to be displayed in the responsive design */}
                                                {window.matchMedia(
                                                  "(min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px) , (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px) , (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px)",
                                                ).matches && (
                                                  <ClientDashboardSidebar />
                                                )}{" "}
                                                {/* FOR IPAD */}
                                                <ClientArchiveSidebar />
                                                <ClientArchiveDashboard />
                                              </>
                                            )}
                                        </div>
                                      </LoaderContext.Provider>
                                    </EncryptedContext.Provider>
                                  </ClientAccessLogContext.Provider>
                                </ClientSharingLogContext.Provider>
                                {/* </ClientArchiveAccessLogContext.Provider>
                  </ClientArchiveSharingLogContext.Provider> */}
                              </ClientRouteContext.Provider>
                            </ClientArchiveCountContext.Provider>
                          </ClientArchiveFileContext.Provider>
                        </ClientArchiveFilesArrayContext.Provider>
                      </ClientDashboardFileContext.Provider>
                    </ClientFilesArrayContext.Provider>
                  </ModalDispatchContext.Provider>
                </NewFileContext.Provider>
              </NotFoundErrorContext.Provider>
            </ComingSoonErrorContext.Provider>
          </BrokenLinkErrorContext.Provider>
        );
      case CLIENT_ROUTES.ACCOUNT:
        return (
          <BrokenLinkErrorContext.Provider
            value={{ brokenLinkError, setBrokenLinkError }}
          >
            <ComingSoonErrorContext.Provider
              value={{ comingSoonError, setCommingSoonError }}
            >
              <NotFoundErrorContext.Provider
                value={{ notFoundError, setNotFoundError }}
              >
                <NewFileContext.Provider value={{ newFile, setNewFile }}>
                  <ModalDispatchContext.Provider value={modalDispatch}>
                    <ClientFilesArrayContext.Provider
                      value={{ clientFilesArray, setClientFilesArray }}
                    >
                      <ClientDashboardFileContext.Provider
                        value={{ clientDisplayedFile, setClientDisplayedFile }}
                      >
                        <ClientArchiveFilesArrayContext.Provider
                          value={{
                            clientArchiveFilesArray,
                            setClientArchiveFilesArray,
                          }}
                        >
                          <ClientArchiveFileContext.Provider
                            value={{ clientArchiveFile, setClientArchiveFile }}
                          >
                            <ClientArchiveCountContext.Provider
                              value={{
                                clientArchiveCount,
                                setClientArchiveCount,
                              }}
                            >
                              <ClientRouteContext.Provider
                                value={CLIENT_ROUTES.ACCOUNT}
                              >
                                <Modal type={modalState} />
                                <div
                                  className="client-dashboard-container"
                                  onClick={(e) => {
                                    hideLogoutbox(e);
                                  }}
                                >
                                  <div className="client-dashboard-background-container">
                                    <div className="client-dashboard-background-image"></div>
                                  </div>
                                  <ClientTopnav />
                                  <ClientSidenav />
                                  {window.matchMedia(
                                    "(min-width: 300px) and (max-width: 600px)",
                                  ).matches && <ClientDashboardSidebar />}{" "}
                                  {/*We need the sidebar here coz it will be one to be displayed in the responsive design */}
                                  {window.matchMedia(
                                    "(min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px) , (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px) , (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px)",
                                  ).matches && <ClientDashboardSidebar />}{" "}
                                  {/* FOR IPAD */}
                                  <ClientAccountSettings />
                                </div>
                              </ClientRouteContext.Provider>
                            </ClientArchiveCountContext.Provider>
                          </ClientArchiveFileContext.Provider>
                        </ClientArchiveFilesArrayContext.Provider>
                      </ClientDashboardFileContext.Provider>
                    </ClientFilesArrayContext.Provider>
                  </ModalDispatchContext.Provider>
                </NewFileContext.Provider>
              </NotFoundErrorContext.Provider>
            </ComingSoonErrorContext.Provider>
          </BrokenLinkErrorContext.Provider>
        );

      case CLIENT_ROUTES.FAQ:
        return (
          <BrokenLinkErrorContext.Provider
            value={{ brokenLinkError, setBrokenLinkError }}
          >
            <ComingSoonErrorContext.Provider
              value={{ comingSoonError, setCommingSoonError }}
            >
              <NotFoundErrorContext.Provider
                value={{ notFoundError, setNotFoundError }}
              >
                <NewFileContext.Provider value={{ newFile, setNewFile }}>
                  <ModalDispatchContext.Provider value={modalDispatch}>
                    <ClientFilesArrayContext.Provider
                      value={{ clientFilesArray, setClientFilesArray }}
                    >
                      <ClientDashboardFileContext.Provider
                        value={{ clientDisplayedFile, setClientDisplayedFile }}
                      >
                        <ClientArchiveFilesArrayContext.Provider
                          value={{
                            clientArchiveFilesArray,
                            setClientArchiveFilesArray,
                          }}
                        >
                          <ClientArchiveFileContext.Provider
                            value={{ clientArchiveFile, setClientArchiveFile }}
                          >
                            <ClientArchiveCountContext.Provider
                              value={{
                                clientArchiveCount,
                                setClientArchiveCount,
                              }}
                            >
                              <ClientRouteContext.Provider
                                value={CLIENT_ROUTES.FAQ}
                              >
                                <Modal type={modalState} />
                                <div
                                  className="client-dashboard-container"
                                  onClick={(e) => {
                                    hideLogoutbox(e);
                                  }}
                                >
                                  <div className="client-dashboard-background-container">
                                    <div className="client-dashboard-background-image"></div>
                                  </div>
                                  <ClientTopnav />
                                  <ClientSidenav />
                                  {window.matchMedia(
                                    "(min-width: 300px) and (max-width: 600px)",
                                  ).matches && <ClientArchiveSidebar />}{" "}
                                  {/*We need the sidebar here coz it will be one to be displayed in the responsive design */}
                                  {window.matchMedia(
                                    "(min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px) , (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px) , (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px)",
                                  ).matches && <ClientArchiveSidebar />}{" "}
                                  {/* FOR IPAD */}
                                  {window.matchMedia(
                                    "(min-width: 300px) and (max-width: 600px)",
                                  ).matches && <ClientDashboardSidebar />}{" "}
                                  {/*We need the sidebar here coz it will be one to be displayed in the responsive design */}
                                  {window.matchMedia(
                                    "(min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px) , (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px) , (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px)",
                                  ).matches && <ClientDashboardSidebar />}{" "}
                                  {/* FOR IPAD */}
                                  <ClientHelp />
                                </div>
                              </ClientRouteContext.Provider>
                            </ClientArchiveCountContext.Provider>
                          </ClientArchiveFileContext.Provider>
                        </ClientArchiveFilesArrayContext.Provider>
                      </ClientDashboardFileContext.Provider>
                    </ClientFilesArrayContext.Provider>
                  </ModalDispatchContext.Provider>
                </NewFileContext.Provider>
              </NotFoundErrorContext.Provider>
            </ComingSoonErrorContext.Provider>
          </BrokenLinkErrorContext.Provider>
        );

      default:
        checkToken();
        return <></>;
    }
  }
  // else {
  //   sessionStorage.setItem("redirect", window.location.pathname);
  //   navigate("/");
  // }
};

export { ClientLoginComponents, ClientPageComponents };
