import React, { useEffect, useRef } from "react";
import "./FilesPane.scss";
import TOOLS_TYPE from "../../ToolsConstants/TOOLS_TYPE";
import { useDispatch, useSelector } from "react-redux";
import {
  resetActiveFileCard,
  setActiveToolType,
  toggleShowToolsSidebar,
} from "../../../store/utilsDataSlice/utilsDataSlice";
import {
  // FeedbackRatingEmoji,
  lazy_load_image,
} from "../../..";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaAngleLeft,
  FaLock,
  FaMinusCircle,
  FaSearch,
  FaUnlock,
} from "react-icons/fa";
import { BsPlusSquare } from "react-icons/bs";
import {
  getToolsFileList,
  // getToolsFileOutputStatus,
  resetOutputStatus,
  resetToolsDetailsData,
  setSearchText,
} from "../../../store/toolsDataSlice/toolsDataSlice";
import getToolTypeIcon from "../../../utils/getToolTypeIcon";
import getFileUnitType from "../../../utils/getFileUnitType";
import dateTimeConverter, {
  DATE_FORMATS,
} from "../../../utils/dateTimeConverter";
import { FILE_ENCRYPTION_STATES } from "../../../client_module/ClientComponents";
import {
  createNewChat,
  resetChatData,
  setInitialMessageSend,
} from "../../../store/consultgptDataSlice/consultgptDataSlice";
import { resetFilesObj } from "../../../store/multiPartUploadDataSlice/multiPartUploadDataSlice";

const FileItem = ({ file_data }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { asgn_id } = useParams();
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const { tools_details_data } = useSelector((state) => state.toolsData);

  const openFile = () => {
    dispatch(toggleShowToolsSidebar(false));
    if (file_data?.id && tools_details_data.data?.id === file_data?.id) return; // so as not to reload
    if (active_tool_type === TOOLS_TYPE.CONSULT) {
      dispatch(setInitialMessageSend(false));
      dispatch(createNewChat(false));
    }
    history(`/tools/${active_tool_type}-file/${file_data.id}`);
    dispatch(resetOutputStatus());
    dispatch(resetToolsDetailsData());
    dispatch(resetActiveFileCard());
  };
  const FileDefaultUserIcon = ({ file }) => {
    return (
      <>
        {file?.file_stage === FILE_ENCRYPTION_STATES.ENCRYPTED && (
          <div className="client-sidebar-file-lock client-sidebar-file-lock-encrypted">
            <FaLock />
          </div>
        )}
        {file?.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD && (
          <div className="client-sidebar-file-lock client-sidebar-file-lock-download">
            <FaUnlock />
          </div>
        )}
        {file?.file_stage === FILE_ENCRYPTION_STATES.DECRYPTED && (
          <div className="client-sidebar-file-lock client-sidebar-file-lock-decrypted">
            <FaUnlock />
          </div>
        )}
        {file?.file_stage === FILE_ENCRYPTION_STATES.REVOKED && (
          <div className="client-sidebar-file-lock client-sidebar-file-lock-revoked">
            <FaMinusCircle />
          </div>
        )}
      </>
    );
  };
  return (
    <div
      className={`files_pane_item files_pane_item-show ${
        asgn_id == file_data.id && "files_pane_item-active"
      }`}
      onClick={openFile}
    >
      <div className="file_service_tag">
        {/*<img
          className="lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ"
        />
        <span className="file_service_tag_name">{active_tool_type}.ai</span> */}
        <img
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + getToolTypeIcon(active_tool_type)}
          alt={`${active_tool_type}.ai`}
        />
      </div>
      <div className="file_feedback">
        {/* <FeedbackRatingEmoji feedback={4} /> */}
      </div>
      <div className="file_name" title={file_data?.file_name}>
        {<FileDefaultUserIcon file={file_data} />}
        <span className="file_name_namecontent">
          {file_data?.file_name || "-"}
        </span>
      </div>
      <div className="file_count">{`${file_data?.count || "0"} ${
        // file_data?.type_count ||
        getFileUnitType({
          cur_tool_type: active_tool_type,
          file_name: file_data?.file_name,
        })
      }`}</div>
      <div className="file_date">
        {file_data?.created_at
          ? dateTimeConverter(
              file_data.created_at,
              DATE_FORMATS.DD_MONTH_YEAR_TIME,
            )
          : "-"}
      </div>
    </div>
  );
};

const FilesList = () => {
  const { tools_list_data } = useSelector((state) => state.toolsData);
  const { active_tool_type } = useSelector((state) => state.utilsData);

  return (
    <div className="files_pane_month_wrapper">
      {/* <div className="files_pane_month_head">April 24</div> */}
      {tools_list_data.loading ? (
        <span style={{ fontStyle: "italic", padding: "2vh 1vh" }}>
          Loading...
        </span>
      ) : (
        <section className="files_pane_list">
          {tools_list_data.data?.length > 0 ? (
            tools_list_data.data?.map((file_data, i) => {
              return <FileItem key={i} file_data={file_data} />;
            })
          ) : (
            <span className="empty_list_data_text">
              {active_tool_type && active_tool_type !== TOOLS_TYPE.CONSULT
                ? "No files here!"
                : "No chats here!"}
            </span>
          )}
        </section>
      )}
    </div>
  );
};

const ToolTabBtn = ({ tool_type, active }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  // const { active_tool_type } = useSelector((state) => state.utilsData);

  const handleClick = () => {
    dispatch(setActiveToolType(tool_type));
    dispatch(resetToolsDetailsData());
    dispatch(resetChatData()); //reseting chat id
    dispatch(resetFilesObj()); //reseting files object
    history(`/tools/${tool_type}-home`);
  };

  return (
    <div
      className={`file_pane_tool_tab_btn ${
        active && "file_pane_tool_tab_btn-active"
      }`}
      onClick={handleClick}
    >
      {tool_type}
    </div>
  );
};

const FilesPane = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tools_list_data } = useSelector((state) => state.toolsData);
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const searchRef = useRef();

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getToolsFileList({
        tool_type: active_tool_type,
        search_text: searchRef.current.value,
      }),
    );
  };

  // const handleClearSearch = () => {
  //   searchRef.current.value = "";
  //   dispatch(setSearchText(null));
  // };

  const handleAddNew = () => {
    dispatch(toggleShowToolsSidebar(false));
    dispatch(resetToolsDetailsData());
    dispatch(resetOutputStatus());
    dispatch(resetActiveFileCard());
    navigate(`/tools/${active_tool_type}-upload`);
  };

  const handleBack = () => {
    dispatch(toggleShowToolsSidebar(true));
    navigate(`/home`);
  };

  useEffect(() => {
    // handleAddNew(); // moves to the home page
    // handleClearSearch(); // clear search when switching tools
    // to invoke the list everytime the id changes
    dispatch(
      getToolsFileList({
        tool_type: active_tool_type,
        search_text: tools_list_data.search_text,
      }),
    ); // FIX: REMOVE COMMENT
  }, [active_tool_type]);

  return (
    <section className="files_pane_section">
      <div className="files_pane_head_container">
        <div className="files_pane_head">
          <div className="files_pane_head_text">
            <div className="files_pane_head_back" onClick={handleBack}>
              <FaAngleLeft />
            </div>
            <span className="files_pane_heading">
              Tools.ai
              {/* <img
                className="tools_ai_logo"
                src={"/Vector (1).svg"}
                alt="tools ai"
              /> */}
            </span>
          </div>
          <div className="files_pane_head_add_new_btn" onClick={handleAddNew}>
            {/* <FaRegPlusSquare color="var(--white)" size={27} /> */}
            <BsPlusSquare color="var(--white)" size={27} />
          </div>
        </div>
        <form className="files_pane_search" onSubmit={handleSearch}>
          <input
            className="file_pane_search_input"
            ref={searchRef}
            value={tools_list_data.search_text || ""}
            onChange={(e) => {
              dispatch(setSearchText(e.target.value));
            }}
            placeholder="Search"
          />
          <button className="file_pane_search_btn" type="submit">
            <FaSearch />
          </button>
        </form>
      </div>
      <section className="files_pane_list_section">
        <div className="file_pane_tools_tab">
          {Object.values(TOOLS_TYPE).map((tool_type, i) => {
            return (
              <ToolTabBtn
                key={i}
                tool_type={tool_type}
                active={active_tool_type === tool_type}
              />
            );
          })}
        </div>
        <section className="files_pane_list_container">
          <section className="files_pane_list_wrapper">
            <FilesList />
          </section>
        </section>
      </section>
    </section>
  );
};

export default FilesPane;
